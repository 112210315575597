import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from '../lotties/dog smoosh.json';
import 'bootstrap/dist/css/bootstrap.min.css';

const OurMission = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('mission');
      if (section) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        const windowScroll = window.scrollY;

        if (
          windowScroll >
          sectionTop - window.innerHeight + sectionHeight / 2
        ) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <div className={`welcome ${isVisible ? 'fade-in' : ''}`} id="mission">
        <h1
          className={`animate__animated ${
            isVisible ? 'animate__fadeInUp' : ''
          }`}
        >
          <b>Our Mission</b>
        </h1>
        <p
          className={`animate__animated ${
            isVisible ? 'animate__fadeInUp' : ''
          }`}
        >
          Our mission is to showcase the captivating stories and personalities
          of our furry friends, while raising awareness about animal welfare and
          promoting pet adoption. We aim to create a visual platform where the
          magic and essence of each animal can shine through.
        </p>

        <div className="animations" style={{ height: '600px', width: '600px' }}>
          <Lottie
            options={defaultOptions}
            animationData={animationData}
            height={600}
            width={600}
          />
        </div>
      </div>
      <hr style={{ width: '500px', color: '#6985ce', height: '50px' }} />
    </>
  );
};

export default OurMission;
