import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar from './components/navbar';
import Banner from './components/Banner';
import OurMission from './components/ourmission';
import WhatWeDo from './components/whatWeDo';
import Welcome from './components/welcome';
import MakeaDiference from './components/HowWeMakeaDiference';
import JoinUs from './components/joinus';
import Footer from './components/footer';

function App() {
  return (
    <div className="coming-soon">
      <Navbar />
      <Banner />
      <Welcome />
      <OurMission />
      <WhatWeDo />
      <MakeaDiference />
      <JoinUs />
      <Footer />
    </div>
  );
}

export default App;
