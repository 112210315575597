import React from 'react';
import '../App.css';
import Lottie from 'lottie-react';
import animationData from '../lotties/dog.json';
import 'bootstrap/dist/css/bootstrap.min.css';

const Banner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <div
        id="home"
        className="container d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <div className="animation" style={{ height: '600px', width: '600px' }}>
          <Lottie
            options={defaultOptions}
            animationData={animationData}
            height={600}
            width={600}
          />
        </div>
        <h1 style={{ color: '#6985ce' }}>
          We Are Working Tirelessly <br />
          <span style={{ color: '#77AA68' }}>
            <b>To Launch This Pawsome Site</b>
          </span>
        </h1>
      </div>
      <hr style={{ width: '500px', color: '#6985ce', heigth: '50px' }} />
    </>
  );
};

export default Banner;
