import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from '../lotties/dog box.json';
import Logo from '../assets/img/Slogan Icon.png';

const Welcome = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <>
      <div className="welcome" id="about">
        <b>
          <h1 className="titles">Welcome to </h1>
        </b>
        <img
          src={Logo}
          style={{
            width: '400px',
            heigh: '200px',
            marginTop: '20px',
            marginBottom: '20px'
          }}
        ></img>
        <p>
          At Pic4Paws, we believe in the power of photography to capture the
          beauty, charm, and spirit of our beloved animal companions. We are a
          passionate community of photographers dedicated to celebrating the
          unique bond between humans and animals through the lens of a camera.
        </p>

        <div className="animations" style={{ height: '600px', width: '600px' }}>
          <Lottie
            options={defaultOptions}
            animationData={animationData}
            height={600}
            width={600}
          />
        </div>
      </div>
      <hr style={{ width: '500px', color: '#6985ce', heigth: '50px' }} />
    </>
  );
};

export default Welcome;
