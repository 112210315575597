import React from 'react';
import Logo from '../assets/img/Small.png';
import { Container, Row, Col } from 'react-bootstrap';
import navIcon1 from '../assets/img/linkedbranco.png';
import navIcon2 from '../assets/img/git icon.png';
import navIcon3 from '../assets/img/twitter branco.png';

const Footer = () => {
  return (
    <footer
      className="text-dark"
      style={{ marginTop: '50px', backgroundColor: '#F1F1F1', padding: '50px' }}
    >
      <div className="container">
        <Row>
          <Col lg={3}>
            <div className="logo">
              <img
                href="#top"
                src={Logo}
                alt="Logo"
                style={{ width: '200px', height: '100px' }}
              />
            </div>
          </Col>
          <Col lg={6}>
            <Row>
              <Col md={6}>
                <h5>Links</h5>
                <ul className="list-unstyled">
                  <li>
                    <a
                      href="#about"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      Welcome
                    </a>
                  </li>
                  <li>
                    <a
                      href="#wedo"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      What We Do
                    </a>
                  </li>
                  <li>
                    <a
                      href="#makediference"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      How We make a Difference
                    </a>
                  </li>
                  <li>
                    <a
                      href="#joinus"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      Join Us
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={6}></Col>
            </Row>
          </Col>
          <Col lg={3} className="text-right">
            <div className="social-icon">
              <a href="#">
                <img
                  src={navIcon1}
                  alt="Icon"
                  style={{ width: '60px', height: '50px' }}
                />
              </a>
              <a href="#">
                <img
                  src={navIcon2}
                  alt="Icon"
                  style={{ width: '65px', height: '50px' }}
                />
              </a>
              <a href="#">
                <img
                  src={navIcon3}
                  alt="Icon"
                  style={{ width: '65px', height: '50px' }}
                />
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
