import React, { useState, useEffect } from 'react';

const JoinUs = () => {
  return (
    <>
      <div className="" id="joinus">
        <div>
          <h1>
            {' '}
            <b>JOIN US</b>{' '}
          </h1>
          <p style={{ width: '50%' }}>
            Whether you're a professional photographer, a pet lover, or simply
            someone who appreciates the beauty of animals, we welcome you to be
            a part of our vibrant community. Together, we can make a lasting
            impact by raising awareness, promoting adoption, and advocating for
            the well-being of animals everywhere.
          </p>
          <br />
          <div>
            <h3>
              {' '}
              <b>
                Capture the Moments. Make a Difference. Join Pic4Paws today!
              </b>{' '}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinUs;
