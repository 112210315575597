import React from 'react';
import logo from '../assets/img/Shelter.png';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container d-flex justify-content-between">
        <a className="navbar-brand" href="#top">
          <img
            id="top"
            src={logo}
            alt="Pic4Paws Logo"
            style={{ height: '75px', width: '125px' }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item" style={{ marginLeft: '10vw' }}>
              <a className="nav-link" href="#about">
                Welcome
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#mission">
                Our Mission
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#wedo">
                What We Do
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#makediference">
                How We Make a Difference
              </a>
            </li>
          </ul>
          <div className="join-us ml-3">
            <a
              className="btn btn-primary"
              href="#joinus"
              style={{ backgroundColor: '#6985ce', marginLeft: '100px' }}
            >
              Join Us
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
