import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from '../lotties/dog reading.json';

const WhatWeDo = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <>
      <div className="welcome" id="wedo">
        <h1>
          {' '}
          <b>What We Do</b>{' '}
        </h1>
        <p>
          Our talented team of volunteer photographers collaborates with animal
          shelters, rescue organizations, and pet owners to capture heartwarming
          and vibrant photographs of animals in need of forever homes. Through
          our lens, we capture the unconditional love, playfulness, and joy that
          our four-legged friends bring to our lives.
        </p>
        <div className="animations" style={{ height: '600px', width: '600px' }}>
          <Lottie
            options={defaultOptions}
            animationData={animationData}
            height={600}
            width={600}
          />
        </div>
      </div>
      <hr style={{ width: '500px', color: '#6985ce', heigth: '50px' }} />
    </>
  );
};

export default WhatWeDo;
