import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from '../lotties/dog owner.json';

const HowWeMakeaDiference = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <>
      <div className="welcome" id="makediference">
        <h1>
          {' '}
          <b>How We Make a Diference</b>{' '}
        </h1>
        <p>
          By sharing these captivating images, we aim to touch the hearts of
          potential adopters, inspiring them to welcome a deserving pet into
          their lives. Additionally, we collaborate with animal advocacy groups
          and organize fundraising events to support animal shelters, veterinary
          care, and spay/neuter programs, ensuring a brighter future for our
          furry companions.
        </p>
        <div className="animations" style={{ height: '600px', width: '600px' }}>
          <Lottie
            options={defaultOptions}
            animationData={animationData}
            height={600}
            width={600}
          />
        </div>
      </div>
      <hr style={{ width: '500px', color: '#6985ce', heigth: '50px' }} />
    </>
  );
};

export default HowWeMakeaDiference;
